.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.card {
  margin: 0 2% !important;
  box-shadow: -19px 10px 5px 0px rgba(247,234,247,1);
}

.infected {
  border-bottom: 10px solid rgba(0, 0, 255, 0.5);
  box-shadow: -5px 2px 3px 0px rgba(247,234,247,0.5) !important;
}

.recovered{
  border-bottom: 10px solid rgba(0, 255, 0, 0.5);
  box-shadow: -5px 2px 3px 0px rgba(247,234,247,0.5) !important;
}

.deaths{
  border-bottom: 10px solid rgba(255, 0, 0, 0.5);
  box-shadow: -5px 2px 3px 0px rgba(247,234,247,0.5) !important;
}

@media only screen and (max-width: 770px) {
  .card {
    margin: 2% 0 !important;
  }
}